import React, { memo, ReactEventHandler, useRef, useState } from 'react'
import { Modal } from '@modules'
import { useGraphqlUser, useModal } from '@hooks'
import { Button, ButtonWithPlus, Tooltip, Collapse } from '@elements'
import styles from '../../checkout.scss'
import { GolfClubAvatarBox } from '@components'
import FolderIcon from '@images/open-folder.svg'
import Arrow from '@images/select-arrow.svg'

export interface SetSellPriceImagesProps {
  open: boolean
  images: any[]
  setImages: (images: any[]) => void
}

const SetSellPriceImages: React.FC<SetSellPriceImagesProps> = props => {
  const [isOpen, setIsOpen] = useState(props.open ? props.open : false)

  const imageIndex = [0, 1, 2, 3, 4]

  const handleFilesUpload = (e: React.DragEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (props.images.length === imageIndex.length) {
      return
    }

    let files
    const uploadedFile = e.currentTarget.files //
    // const hasFiles = uploadedFile?.length === 1
    const isAcceptableFileTypes = ['image/jpg', 'image/jpeg', 'image/png'].includes(uploadedFile?.[0]?.type as string)
    const isAcceptableFileSizes = (uploadedFile?.[0]?.size as number) <= 4958690

    if (isAcceptableFileTypes && isAcceptableFileSizes) {
      if ((e as React.DragEvent<HTMLInputElement>).dataTransfer) {
        files = (e as React.DragEvent<HTMLInputElement>).dataTransfer.files
      } else if (e.target) {
        files = e.currentTarget.files
      }
    } else {
      return
    }

    const reader = new FileReader()

    reader.onload = e => {
      const avatarFile = reader.result

      if (avatarFile) {
        const newImages = props.images.slice()
        newImages.push(avatarFile)
        props.setImages(newImages)
      }
    }

    if (files) {
      reader.readAsDataURL(files[0])
    }
  }

  const deleteImage = (index: number) => {
    const newImages = props.images.slice()
    newImages.splice(index, 1)
    props.setImages(newImages)
  }

  return (
    <div className={'checkout-payment-info-set-price-form-image-upload-wrapper'}>
      <div
        className={'checkout-payment-info-set-price-form-image-upload'}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className={'checkout-payment-info-set-price-form-image-upload-tooltip'}>
          <Tooltip>
            Images are not required, but they will help buyers determine which listing they want to purchase.
            Transparency is key!
          </Tooltip>
          <div className={'checkout-payment-info-set-price-form-image-upload-text'}>UPLOAD IMAGES</div>
        </div>
        <div className={'arrow-flip'} data-toggle={isOpen}>
          <Arrow />
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <div className={'checkout-image-upload'}>
          <p>
            You can upload a maximum of {imageIndex.length} images in JPG or PNG format. Buyers will see images in the
            order they are uploaded.
          </p>
          <label htmlFor="upload-images" className={'checkout-image-upload-content-select-label'}>
            <div className={'checkout-image-upload-content'}>
              <div className={'checkout-image-upload-content-photo-inner'}>
                <FolderIcon width={80} height={80} style={{ paddingBottom: '10px' }} />
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFilesUpload}
                  onDragEnd={handleFilesUpload}
                />
                <span className={'checkout-image-upload-content-select-click'}>Click to choose a file</span>
                <span> or drag it here</span>
              </div>
            </div>
          </label>
          <div className={'checkout-image-upload-content-images'}>
            {imageIndex.map(i => {
              return (
                <GolfClubAvatarBox image={props.images[i]} index={i + 1} key={i} handleDelete={() => deleteImage(i)} />
              )
            })}
          </div>
          <div>Click image to remove</div>
        </div>
      </Collapse>
    </div>
  )
}

export default memo(SetSellPriceImages)
